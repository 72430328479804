@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap);
body {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
}

.AboutMe_containerAboutMe__3TNnx {
  font-family: "Ubuntu", sans-serif;
  font-weight: 300;
  background: #f8f8fa;
  background: linear-gradient(178deg, #f8f8fa 0%, white 80%);
  min-height: 100vh;
  margin: 260px auto;
  padding: 100px 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

@media (max-width: 767px) {
  .AboutMe_containerAboutMe__3TNnx {
    margin: 100px auto;
  }
}

.AboutMe_upperpanel__2nS9q {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 50%;
  padding: 50px 0px;
  margin-bottom: 55px;
}

.AboutMe_upperpanel__2nS9q div {
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100%;
  margin: 0 auto;
}

.AboutMe_upperpanel__2nS9q div:nth-of-type(2) {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 25px auto;
}

.AboutMe_upperpanel__2nS9q div:nth-of-type(2) .AboutMe_imgDiv__1EiyV {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding: 0px 0px 5px 5px;
}

.AboutMe_upperpanel__2nS9q div:nth-of-type(2) .AboutMe_imgDiv__1EiyV img {
  width: 100%;
  max-height: 180px;
  object-fit: contain;
}

.AboutMe_upperpanel__2nS9q p {
  color: #665f82;
  border-left: 1px solid #ff64cb;
  padding-left: 55px;
  margin-left: 55px;
  -webkit-flex: 2 1;
          flex: 2 1;
  width: 100%;
  text-align: left;
  font-size: 1vw;
  line-height: 1.8em;
  position: relative;
}

.AboutMe_upperpanel__2nS9q p:before {
  content: ' "';
  color: #221e41;
  font-size: 6vw;
  position: absolute;
  top: -45px;
  left: 40px;
  margin-bottom: 25px;
  -webkit-transform: rotateY(45deg) rotateX(-45deg);
          transform: rotateY(45deg) rotateX(-45deg);
  font-weight: 800;
}

@media (max-width: 1300px) and (min-width: 1025px) {
  .AboutMe_upperpanel__2nS9q p {
    font-size: 1.5vw;
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .AboutMe_upperpanel__2nS9q {
    width: 75%;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 50px 0px;
  }
  .AboutMe_upperpanel__2nS9q div {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .AboutMe_upperpanel__2nS9q div .AboutMe_imgDiv__1EiyV {
    display: none;
  }
  .AboutMe_upperpanel__2nS9q div .AboutMe_imgDiv__1EiyV img {
    display: none;
  }
  .AboutMe_upperpanel__2nS9q p {
    font-size: 3vw;
  }
  .AboutMe_upperpanel__2nS9q p::before {
    display: none;
  }
}

@media (max-width: 767px) {
  .AboutMe_upperpanel__2nS9q {
    width: 75%;
  }
  .AboutMe_upperpanel__2nS9q div {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .AboutMe_upperpanel__2nS9q div .AboutMe_imgDiv__1EiyV {
    display: none;
  }
  .AboutMe_upperpanel__2nS9q div .AboutMe_imgDiv__1EiyV img {
    display: none;
  }
  .AboutMe_upperpanel__2nS9q p {
    font-size: 4vw;
    border: none;
    margin: 0 auto;
    padding: 25px 0px 0px 15px;
  }
  .AboutMe_upperpanel__2nS9q p:before {
    display: none;
  }
}

.AboutMe_mobileImg__3U186 {
  display: none;
}

@media (max-width: 767px) {
  .AboutMe_mobileImg__3U186 {
    display: block;
    float: left;
    margin-right: 15px;
    margin-top: 5px;
    max-height: 150px;
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .AboutMe_mobileImg__3U186 {
    display: block;
    float: left;
    margin-top: 55px;
    margin-right: 25px;
  }
}

.AboutMe_bottompanel__Rk9MR {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  width: 50%;
  padding: 50px 0px;
}

.AboutMe_bottompanel__Rk9MR h3 {
  text-align: center;
  color: #665f82;
  font-size: 1.2vw;
  font-weight: 700;
  color: #665f82;
  margin: 25px auto;
  position: relative;
}

.AboutMe_bottompanel__Rk9MR div {
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100%;
  margin: 0 auto;
}

.AboutMe_bottompanel__Rk9MR .AboutMe_frontEnd__3iluD {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  margin: 25px auto;
  border-bottom: 1px solid #ccc;
}

.AboutMe_bottompanel__Rk9MR .AboutMe_frontEnd__3iluD div {
  width: 100%;
  color: #665f82;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 15px auto;
  font-size: 3vw;
}

.AboutMe_bottompanel__Rk9MR .AboutMe_frontEnd__3iluD div p {
  font-size: 1vw;
}

.AboutMe_bottompanel__Rk9MR .AboutMe_skills__2-6ps {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin: 25px auto;
}

.AboutMe_bottompanel__Rk9MR .AboutMe_skills__2-6ps div {
  border-right: 1px solid #ccc;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.AboutMe_bottompanel__Rk9MR .AboutMe_skills__2-6ps div div {
  width: 100%;
  color: #665f82;
  margin: 15px auto;
  font-size: 3vw;
}

.AboutMe_bottompanel__Rk9MR .AboutMe_skills__2-6ps div div p {
  font-size: 1vw;
}

.AboutMe_bottompanel__Rk9MR .AboutMe_skills__2-6ps div:nth-last-of-type(1) {
  border: none;
}

.AboutMe_bottompanel__Rk9MR .AboutMe_skills__2-6ps div h3 {
  text-align: center;
  font-size: 1.2vw;
  font-weight: 700;
  color: #665f82;
}

@media (max-width: 766px) {
  .AboutMe_bottompanel__Rk9MR {
    width: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 50px 0px;
  }
  .AboutMe_bottompanel__Rk9MR h3 {
    font-size: 5vw;
    margin-top: 55px;
    margin-bottom: 0px;
  }
  .AboutMe_bottompanel__Rk9MR .AboutMe_frontEnd__3iluD div {
    font-size: 10vw;
  }
  .AboutMe_bottompanel__Rk9MR .AboutMe_frontEnd__3iluD div p {
    font-size: 15px;
  }
  .AboutMe_bottompanel__Rk9MR .AboutMe_skills__2-6ps {
    -webkit-flex-direction: column;
            flex-direction: column;
    display: none;
  }
}

@media screen and (max-width: 1024px) and (min-width: 375px) {
  .AboutMe_bottompanel__Rk9MR {
    width: 75%;
  }
  .AboutMe_bottompanel__Rk9MR h3 {
    font-size: 5vw;
  }
  .AboutMe_bottompanel__Rk9MR .AboutMe_frontEnd__3iluD div {
    font-size: 8vw;
  }
  .AboutMe_bottompanel__Rk9MR .AboutMe_frontEnd__3iluD div p {
    font-size: 3vw;
  }
  .AboutMe_bottompanel__Rk9MR .AboutMe_skills__2-6ps div h3 {
    font-size: 25px;
  }
  .AboutMe_bottompanel__Rk9MR .AboutMe_skills__2-6ps div div {
    font-size: 5vw;
  }
  .AboutMe_bottompanel__Rk9MR .AboutMe_skills__2-6ps div div p {
    font-size: 25px;
  }
}

.AboutMe_description__25jhN {
  border: 1px solid #0004ff;
}

.AboutMe_mobileSkills__1ImxP {
  display: none;
}

@media (max-width: 766px) {
  .AboutMe_mobileSkills__1ImxP {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .AboutMe_mobileSkills__1ImxP h3 {
    font-size: 5vw;
    margin-bottom: 25px;
  }
  .AboutMe_mobileSkills__1ImxP div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .AboutMe_mobileSkills__1ImxP div div {
    display: -webkit-flex;
    display: flex;
    font-size: 7vw;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-bottom: 1px solid #ccc;
    color: #665f82;
  }
  .AboutMe_mobileSkills__1ImxP div div p {
    font-size: 15px;
  }
}
/*# sourceMappingURL=AboutMe.module.css.map */
.Title_containerTitle__22TIl {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0 auto;
}

@media (max-width: 1024px) {
  .Title_containerTitle__22TIl {
    width: 75%;
  }
}

h2 {
  color: #221e41;
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

h2:nth-of-type(2) {
  color: #6e00ff;
  font-size: 5vw;
  padding: 0;
  margin: 0;
  font-weight: 800;
}

@media (max-width: 1024px) {
  h2 {
    font-size: 3vw;
  }
  h2:nth-of-type(2) {
    font-size: 10vw;
  }
}
/*# sourceMappingURL=Title.module.css.map */
.CustomCursor_cursorMain__33Kz0 {
  width: 5px;
  height: 5px;
  position: absolute;
  z-index: 999999999;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 50%;
  pointer-events: none;
}

@media (max-width: 1024px) {
  .CustomCursor_cursorMain__33Kz0 {
    display: none;
  }
}

.CustomCursor_cursorText__2SQTp {
  background-color: #41361e;
  width: 150px;
  height: 150px;
  mix-blend-mode: difference;
  position: absolute;
  z-index: 999999999;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 50%;
  pointer-events: none;
}

@media (max-width: 1024px) {
  .CustomCursor_cursorText__2SQTp {
    display: none;
  }
}

.CustomCursor_textCursor__oZp8j {
  color: #fff;
  width: 100px;
  height: 100px;
}
/*# sourceMappingURL=CustomCursor.module.css.map */
.Header_mainContainer__PednR {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.Header_leftWrapper__1Eq4_ {
  background-color: #221e41;
  -webkit-flex: 1.5 1;
          flex: 1.5 1;
  width: 100%;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Header_leftWrapper__1Eq4_ header {
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 90%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  margin: 0 auto;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
}

.Header_leftWrapper__1Eq4_ header div {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Header_leftWrapper__1Eq4_ header div img {
  width: 20%;
}

.Header_leftWrapper__1Eq4_ header nav {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-flex: 5 1;
          flex: 5 1;
}

.Header_leftWrapper__1Eq4_ header nav ul {
  width: 40%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  list-style: none;
}

.Header_leftWrapper__1Eq4_ header nav ul a {
  color: #d6d5d5;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  font-family: "Ubuntu", sans-serif;
  transition: 0.3s;
}

.Header_leftWrapper__1Eq4_ header nav ul a:hover {
  color: white;
}

@media (max-width: 1024px) {
  .Header_leftWrapper__1Eq4_ header div img {
    width: 10%;
  }
  .Header_leftWrapper__1Eq4_ header nav {
    display: none;
  }
}

@media (max-width: 1500px) and (min-width: 1025px) {
  .Header_leftWrapper__1Eq4_ header nav ul {
    width: 80%;
  }
}

.Header_leftWrapper__1Eq4_ .Header_active__vmCnS {
  color: white;
}

.Header_leftWrapper__1Eq4_ .Header_lightWrapper__Y6mTP {
  background-color: #6e00ff;
  border-top-right-radius: 167px;
  border-bottom-right-radius: 167px;
  width: 90%;
  height: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Header_leftWrapper__1Eq4_ .Header_lightWrapper__Y6mTP .Header_mobilHeaderSVG__1XwUx {
  display: none;
}

@media (max-width: 1024px) {
  .Header_leftWrapper__1Eq4_ .Header_lightWrapper__Y6mTP .Header_mobilHeaderSVG__1XwUx {
    display: -webkit-flex;
    display: flex;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    width: 80%;
    margin-right: -30px;
    height: 100%;
  }
}

.Header_leftWrapper__1Eq4_ .Header_lightWrapper__Y6mTP div {
  margin-top: 200px;
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Header_leftWrapper__1Eq4_ .Header_lightWrapper__Y6mTP h1 {
  font-size: 5vw;
  margin: 0;
  margin-bottom: -20px;
  color: white;
  font-family: "Ubuntu", sans-serif;
  font-weight: 800;
}

.Header_leftWrapper__1Eq4_ .Header_lightWrapper__Y6mTP h2 {
  font-size: 2vw;
  font-weight: normal;
  margin: 15px 0;
  font-weight: 400;
  color: white;
  display: block;
  font-family: "Ubuntu", sans-serif;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Header_leftWrapper__1Eq4_ .Header_lightWrapper__Y6mTP h2 span {
  font-size: 0.7vw;
  font-weight: 300;
}

.Header_leftWrapper__1Eq4_ .Header_lightWrapper__Y6mTP button {
  border-top-right-radius: 167px;
  border-bottom-right-radius: 167px;
  font-size: 15px;
  margin-top: 15px;
  color: #221e41;
  outline: none;
  color: white;
  cursor: pointer;
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  background-color: #ff64cb;
  width: 40%;
  padding: 15px 0px;
  border: none;
  transition: 0.1s;
}

.Header_leftWrapper__1Eq4_ .Header_lightWrapper__Y6mTP button:hover {
  background-color: #e83e8c;
}

@media (max-width: 1024px) {
  .Header_leftWrapper__1Eq4_ .Header_lightWrapper__Y6mTP div {
    width: 80%;
    margin-top: 100px;
    text-align: center;
  }
  .Header_leftWrapper__1Eq4_ .Header_lightWrapper__Y6mTP h1 {
    font-size: 11vw;
    width: 100%;
  }
  .Header_leftWrapper__1Eq4_ .Header_lightWrapper__Y6mTP h2 {
    font-size: 5vw;
    width: 100%;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .Header_leftWrapper__1Eq4_ .Header_lightWrapper__Y6mTP h2 span {
    font-size: 2vw;
    font-weight: 300;
    width: 15px;
  }
  .Header_leftWrapper__1Eq4_ .Header_lightWrapper__Y6mTP button {
    border-radius: 167px;
    margin: 0 auto;
    width: 60%;
    font-size: 2vw;
  }
}

@media (max-width: 1300px) and (min-width: 1025px) {
  .Header_leftWrapper__1Eq4_ .Header_lightWrapper__Y6mTP div {
    width: 90%;
  }
}

.Header_rightWrapper__rdlqR {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  height: 89vh;
}

.Header_rightWrapper__rdlqR .Header_triggerSocialAnime__2iAzV {
  -webkit-flex: 0 1;
          flex: 0 1;
}

.Header_rightWrapper__rdlqR div {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: 100%;
  margin: 0 auto;
  height: 10%;
  -webkit-align-self: flex-start;
          align-self: flex-start;
  justify-self: flex-start;
  align-items: flex-start;
  position: relative;
}

.Header_rightWrapper__rdlqR div div {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  position: fixed;
  top: 0;
  width: 50px;
  margin-top: 20px;
  z-index: 9999;
}

.Header_rightWrapper__rdlqR div div span {
  background-color: #221e41;
  color: white;
  padding: 6px 8px;
  padding-top: 8px;
  border-radius: 100%;
  margin: 5px;
  cursor: pointer;
  transition: 1s;
}

.Header_rightWrapper__rdlqR div div span:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

@media (max-width: 1024px) {
  .Header_rightWrapper__rdlqR {
    display: none;
  }
}

.Header_notinView__1t0Cu {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  z-index: 9999;
}

.Header_notinView__1t0Cu span {
  margin: 5px auto !important;
}

.Header_showArrowUp__2JyKY {
  display: -webkit-flex;
  display: flex;
  position: fixed;
  background-color: #ff64cb;
  font-size: 3vw;
  bottom: 5%;
  right: 8%;
  border-radius: 100%;
  cursor: pointer;
  z-index: 99;
}

@media (max-width: 1024px) {
  .Header_showArrowUp__2JyKY {
    padding: 15px;
    bottom: 2%;
    right: 5%;
  }
}

.Header_hideArrowUp__1tIro {
  display: none;
}

.Header_popUpThemeGmail__32DIs {
  font-size: 12px;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 25px;
  position: fixed;
  height: 60px !important;
  padding-bottom: 15px !important;
  font-family: "Ubuntu", sans-serif;
}

.Header_backtoTopPop__22Ozs {
  height: 20px !important;
  padding-bottom: 25px !important;
  font-family: "Ubuntu", sans-serif;
}
/*# sourceMappingURL=Header.module.css.map */
footer {
  margin-top: 100px;
  font-family: "Ubuntu", sans-serif;
  background-color: #221e41;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  height: 100vh;
}

.Footer_lightContainer__1mRpG {
  background-color: #6e00ff;
  border-top-left-radius: 167px;
  width: 98%;
  height: 55%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Footer_itemsContainer__15lLX {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 100%;
}

.Footer_itemsContainer__15lLX h3 {
  color: white;
  font-family: "Ubuntu", sans-serif;
  text-align: center;
}

.Footer_contact__8nNuz {
  color: white;
  font-family: "Ubuntu", sans-serif;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Footer_contact__8nNuz span {
  margin: 15px 0px;
  font-weight: 300;
  font-size: 1vw;
  cursor: pointer;
}

@media (max-width: 1024px) and (min-width: 768px) {
  .Footer_contact__8nNuz span {
    font-size: 2vw;
    margin: 5px 0px;
  }
}

@media (max-width: 767px) {
  .Footer_contact__8nNuz span {
    font-size: 3vw;
    margin: 5px 0px;
  }
}

@media (max-width: 767px) {
  .Footer_disableonMobile__9dhAq {
    display: none;
  }
}

.Footer_footernav__2oaOS {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Footer_footernav__2oaOS ul {
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Footer_footernav__2oaOS ul li {
  margin: 15px 0px;
}

.Footer_footernav__2oaOS ul li a {
  color: #e5e2e2;
  text-decoration: none;
}

@media (max-width: 767px) {
  .Footer_footernav__2oaOS {
    display: none;
  }
}

.Footer_buildTogether__yrkdK {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 50%;
  overflow: hidden;
  color: white;
  font-weight: 800;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Footer_buildTogether__yrkdK h1 {
  margin: 0 auto;
  font-size: 4vw;
}

.Footer_buildTogether__yrkdK h2 {
  color: #c4c0c0;
  font-size: 1.8vw;
  font-weight: 300;
}

@media (max-width: 767px) {
  .Footer_buildTogether__yrkdK h1 {
    font-size: 7vw;
  }
  .Footer_buildTogether__yrkdK h2 {
    font-size: 4vw;
  }
}

.Footer_copyright__2OWH4 {
  padding: 50px;
  color: #afafaf;
  text-align: center;
}

@media (max-width: 767px) {
  .Footer_copyright__2OWH4 {
    font-size: 2vw;
  }
}
/*# sourceMappingURL=Footer.module.css.map */
.BurgerMenu_burger__15wnS {
  cursor: pointer;
  display: none;
}

@media (max-width: 1024px) {
  .BurgerMenu_burger__15wnS {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 25px;
    height: 25px;
    padding: 3%;
    top: 2%;
    right: 5%;
    background-color: #ff64cb;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    position: fixed;
    z-index: 9999;
  }
  .BurgerMenu_burger__15wnS div {
    margin: 5%;
    width: 80%;
    height: 10%;
    background-color: white;
  }
}

.BurgerMenu_mobileMenu__12rTm {
  display: none;
}

@media (max-width: 1024px) {
  .BurgerMenu_mobileMenu__12rTm {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    opacity: 0;
    background-color: white;
    top: 0;
    right: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
  .BurgerMenu_mobileMenu__12rTm div {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    width: 100%;
    -webkit-flex: 1 1;
            flex: 1 1;
  }
}

.BurgerMenu_mobileNav__2LHaC {
  -webkit-flex: 4 1;
          flex: 4 1;
  box-sizing: border-box;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  width: 100%;
  font-size: 7vw;
  font-family: "Open Sans", sans-serif;
}

.BurgerMenu_mobileNav__2LHaC ul {
  list-style: none;
  text-decoration: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  height: 40%;
  -webkit-align-items: center;
          align-items: center;
  width: 80%;
}

.BurgerMenu_mobileNav__2LHaC ul a {
  font-weight: 400;
  color: #221e41;
  text-decoration: none;
}

.BurgerMenu_active__207vz {
  color: #ff64cb !important;
}
/*# sourceMappingURL=BurgerMenu.module.css.map */
.MyWork_containerAboutMe__2JPjc {
  font-family: "Ubuntu", sans-serif;
  font-weight: 300;
  background: #f8f8fa;
  background: linear-gradient(178deg, #f8f8fa 0%, white 80%);
  min-height: 100vh;
  margin: 260px auto;
  padding: 100px 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

@media (max-width: 767px) {
  .MyWork_containerAboutMe__2JPjc {
    margin: 100px auto;
  }
}

.MyWork_upperpanel__2UMxW {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 50%;
  padding: 50px 0px;
  margin-bottom: 55px;
}

.MyWork_upperpanel__2UMxW div {
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100%;
  margin: 0 auto;
}

.MyWork_upperpanel__2UMxW div:nth-of-type(2) {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 25px auto;
}

.MyWork_upperpanel__2UMxW div:nth-of-type(2) .MyWork_imgDiv__2GCzi {
  background-color: #221e41;
  padding: 0px 0px 5px 5px;
}

.MyWork_upperpanel__2UMxW div:nth-of-type(2) .MyWork_imgDiv__2GCzi img {
  width: 100%;
}

.MyWork_upperpanel__2UMxW p {
  color: #665f82;
  border-left: 1px solid #ff64cb;
  padding-left: 55px;
  margin-left: 55px;
  -webkit-flex: 2 1;
          flex: 2 1;
  width: 100%;
  text-align: left;
  font-size: 1vw;
  line-height: 1.8em;
  position: relative;
}

.MyWork_upperpanel__2UMxW p:before {
  content: ' "';
  color: #221e41;
  font-size: 6vw;
  position: absolute;
  top: -45px;
  left: 40px;
  margin-bottom: 25px;
  -webkit-transform: rotateY(45deg) rotateX(-45deg);
          transform: rotateY(45deg) rotateX(-45deg);
  font-weight: 800;
}

@media (max-width: 1300px) and (min-width: 1025px) {
  .MyWork_upperpanel__2UMxW p {
    font-size: 1.5vw;
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .MyWork_upperpanel__2UMxW {
    width: 75%;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 50px 0px;
  }
  .MyWork_upperpanel__2UMxW div {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .MyWork_upperpanel__2UMxW p {
    font-size: 3vw;
  }
  .MyWork_upperpanel__2UMxW p::before {
    display: none;
  }
}

@media (max-width: 767px) {
  .MyWork_upperpanel__2UMxW {
    width: 75%;
  }
  .MyWork_upperpanel__2UMxW div {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .MyWork_upperpanel__2UMxW p {
    font-size: 4vw;
    border: none;
    margin: 0 auto;
    padding: 25px 0px;
  }
  .MyWork_upperpanel__2UMxW p:before {
    display: none;
  }
}

.MyWork_bottompanel__1R48n {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  width: 50%;
  padding: 50px 0px;
}

.MyWork_bottompanel__1R48n h3 {
  text-align: center;
  color: #665f82;
  font-size: 1.2vw;
  font-weight: 700;
  color: #665f82;
  margin: 25px auto;
  position: relative;
}

.MyWork_bottompanel__1R48n div {
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100%;
  margin: 0 auto;
}

.MyWork_bottompanel__1R48n .MyWork_frontEnd__h_0qh {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  margin: 25px auto;
  border-bottom: 1px solid #ccc;
}

.MyWork_bottompanel__1R48n .MyWork_frontEnd__h_0qh div {
  width: 100%;
  color: #665f82;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 15px auto;
  font-size: 3vw;
}

.MyWork_bottompanel__1R48n .MyWork_frontEnd__h_0qh div p {
  font-size: 1vw;
}

.MyWork_bottompanel__1R48n .MyWork_skills__1PHy2 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  margin: 25px auto;
}

.MyWork_bottompanel__1R48n .MyWork_skills__1PHy2 div {
  border-right: 1px solid #ccc;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.MyWork_bottompanel__1R48n .MyWork_skills__1PHy2 div div {
  width: 100%;
  color: #665f82;
  margin: 15px auto;
  font-size: 3vw;
}

.MyWork_bottompanel__1R48n .MyWork_skills__1PHy2 div div p {
  font-size: 1vw;
}

.MyWork_bottompanel__1R48n .MyWork_skills__1PHy2 div:nth-last-of-type(1) {
  border: none;
}

.MyWork_bottompanel__1R48n .MyWork_skills__1PHy2 div h3 {
  text-align: center;
  font-size: 1.2vw;
  font-weight: 700;
  color: #665f82;
}

@media (max-width: 766px) {
  .MyWork_bottompanel__1R48n {
    width: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 50px 0px;
  }
  .MyWork_bottompanel__1R48n h3 {
    font-size: 5vw;
    margin-top: 55px;
    margin-bottom: 0px;
  }
  .MyWork_bottompanel__1R48n .MyWork_frontEnd__h_0qh div {
    font-size: 10vw;
  }
  .MyWork_bottompanel__1R48n .MyWork_frontEnd__h_0qh div p {
    font-size: 15px;
  }
  .MyWork_bottompanel__1R48n .MyWork_skills__1PHy2 {
    -webkit-flex-direction: column;
            flex-direction: column;
    display: none;
  }
}

@media screen and (max-width: 1024px) and (min-width: 375px) {
  .MyWork_bottompanel__1R48n {
    width: 75%;
  }
  .MyWork_bottompanel__1R48n h3 {
    font-size: 5vw;
  }
  .MyWork_bottompanel__1R48n .MyWork_frontEnd__h_0qh div {
    font-size: 8vw;
  }
  .MyWork_bottompanel__1R48n .MyWork_frontEnd__h_0qh div p {
    font-size: 3vw;
  }
  .MyWork_bottompanel__1R48n .MyWork_skills__1PHy2 div h3 {
    font-size: 25px;
  }
  .MyWork_bottompanel__1R48n .MyWork_skills__1PHy2 div div {
    font-size: 5vw;
  }
  .MyWork_bottompanel__1R48n .MyWork_skills__1PHy2 div div p {
    font-size: 25px;
  }
}

.MyWork_description__2-Ss6 {
  border: 1px solid #0004ff;
}

.MyWork_mobileSkills__1xQSS {
  display: none;
}

@media (max-width: 766px) {
  .MyWork_mobileSkills__1xQSS {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .MyWork_mobileSkills__1xQSS h3 {
    font-size: 5vw;
    margin-bottom: 25px;
  }
  .MyWork_mobileSkills__1xQSS div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .MyWork_mobileSkills__1xQSS div div {
    display: -webkit-flex;
    display: flex;
    font-size: 7vw;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-bottom: 1px solid #ccc;
    color: #665f82;
  }
  .MyWork_mobileSkills__1xQSS div div p {
    font-size: 15px;
  }
}

.MyWork_mainContainer__Bpx4P {
  background: #f8f8fa;
  background: linear-gradient(178deg, #f8f8fa 0%, white 80%);
  padding: 55px 0px;
}

.MyWork_mainContainer__Bpx4P h4 {
  font-weight: 400;
  font-family: "Ubuntu", sans-serif;
  text-align: center;
  color: #221e41;
  cursor: pointer;
}

.MyWork_mainContainer__Bpx4P h4:hover {
  text-decoration: underline;
}

.MyWork_container__1Xar- {
  width: 60%;
  font-family: "Ubuntu", sans-serif;
  margin: 55px auto;
}

@media (max-width: 1024px) {
  .MyWork_container__1Xar- {
    width: 90%;
  }
}

.MyWork_cardContainer__3wOu7 {
  margin: 100px auto;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  padding: 55px;
  overflow: hidden;
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
}

@media (max-width: 1024px) {
  .MyWork_cardContainer__3wOu7 {
    padding: 55px 0px;
    border-radius: 0px;
    margin: 50px auto;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.MyWork_itemContainer__2Rjsk {
  min-height: 380px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

@media (max-width: 1024px) {
  .MyWork_itemContainer__2Rjsk {
    -webkit-flex-direction: column;
            flex-direction: column;
    min-height: 280px;
  }
}

.MyWork_cardText__3JNpi {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-order: 1;
          order: 1;
}

.MyWork_cardText__3JNpi h2 {
  color: white;
  font-size: 3vw;
  text-shadow: 3px 3px 10px rgba(31, 38, 135, 0.37);
  cursor: pointer;
  font-weight: 500;
}

.MyWork_cardText__3JNpi h3 {
  color: white;
  font-size: 1vw;
  font-weight: 300;
}

@media (max-width: 1024px) {
  .MyWork_cardText__3JNpi {
    padding: 25px 0px;
  }
  .MyWork_cardText__3JNpi h2 {
    font-size: 5vw;
  }
  .MyWork_cardText__3JNpi h3 {
    font-size: 3vw;
    margin: 25px auto;
  }
}

.MyWork_stackUsed__2PyZ8 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
}

.MyWork_stackUsed__2PyZ8 div {
  font-size: 0.8vw;
  color: white;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 1px 62px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  padding: 10px;
  margin: 5px;
}

@media (max-width: 1024px) {
  .MyWork_stackUsed__2PyZ8 div {
    font-size: 2.5vw;
  }
}

.MyWork_links__3bgRd {
  color: white;
  font-size: 2vw;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  margin: 0 auto;
  width: 50%;
}

.MyWork_links__3bgRd svg {
  cursor: pointer;
  transition: 0.1s;
}

.MyWork_links__3bgRd svg:hover {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

@media (max-width: 1024px) {
  .MyWork_links__3bgRd {
    padding-top: 0;
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 8vw;
    margin-top: 25px;
  }
}

.MyWork_leftOrder__3Gpnq {
  -webkit-order: -1;
          order: -1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

@media (max-width: 1024px) {
  .MyWork_leftOrder__3Gpnq {
    margin: 0 auto;
  }
}

.MyWork_rightOrder__3N9nq {
  -webkit-order: 1;
          order: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

@media (max-width: 1024px) {
  .MyWork_rightOrder__3N9nq {
    margin: 0 auto;
    -webkit-order: -1;
            order: -1;
  }
}

.MyWork_description__2-Ss6 {
  border: none;
}
/*# sourceMappingURL=MyWork.module.css.map */
.DesktopImage_parralax__PSCCG {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  display: -webkit-flex;
  display: flex;
  padding: 15px;
}

@media (max-width: 767px) {
  .DesktopImage_parralax__PSCCG {
    padding: 15px;
  }
}

.DesktopImage_cardImageWrapper__2nIf4 {
  -webkit-transform: translateZ(60px);
          transform: translateZ(60px);
  width: 100%;
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
  transition: 1s;
}

.DesktopImage_cardImageWrapper__2nIf4 img {
  width: 100%;
  object-fit: contain;
}

@media (max-width: 1024px) {
  .DesktopImage_cardImageWrapper__2nIf4 img {
    max-height: 200px;
    object-fit: contain;
  }
}

.DesktopImage_leftOrder__yEAO3 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: -200px;
}

@media (max-width: 1024px) {
  .DesktopImage_leftOrder__yEAO3 {
    margin: 0 auto;
  }
}

.DesktopImage_rightOrder__3xCaS {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-right: -200px;
}

@media (max-width: 1024px) {
  .DesktopImage_rightOrder__3xCaS {
    margin: 0 auto;
  }
}
/*# sourceMappingURL=DesktopImage.module.css.map */
.MobileTabletImage_container__1yJfA {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: 100%;
  margin-bottom: 15px;
}

.MobileTabletImage_parralax__3hAQH {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

@media (max-width: 767px) {
  .MobileTabletImage_parralax__3hAQH {
    padding: 15px;
  }
}

.MobileTabletImage_cardImageWrapper__ORP-o {
  -webkit-transform: translateZ(60px);
          transform: translateZ(60px);
  width: 100%;
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
}

.MobileTabletImage_cardImageWrapper__ORP-o img {
  width: 100%;
}

@media (max-width: 1024px) {
  .MobileTabletImage_cardImageWrapper__ORP-o img {
    max-height: 200px;
    object-fit: contain;
  }
}
/*# sourceMappingURL=MobileTabletImage.module.css.map */
.SelectDevice_deviceRight__cJheT {
  width: 100%;
  color: #d1cdcd;
  margin: 0 auto;
  padding-top: 55px;
  font-size: 1.5vw;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 0 auto;
}

.SelectDevice_deviceRight__cJheT div:nth-child(1) {
  -webkit-order: 2;
          order: 2;
}

.SelectDevice_deviceRight__cJheT div:nth-child(2) {
  -webkit-order: 1;
          order: 1;
}

.SelectDevice_deviceRight__cJheT svg {
  cursor: pointer;
  transition: 0.2s;
}

.SelectDevice_deviceRight__cJheT svg:hover {
  color: white;
}

@media (max-width: 1024px) {
  .SelectDevice_deviceRight__cJheT {
    padding-top: 0px;
    width: 80%;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    font-size: 5vw;
  }
}

.SelectDevice_deviceLeft__3mkud {
  width: 100%;
  color: #d1cdcd;
  margin: 0 auto;
  padding-top: 55px;
  font-size: 1.5vw;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 0 auto;
}

.SelectDevice_deviceLeft__3mkud svg {
  cursor: pointer;
}

.SelectDevice_deviceLeft__3mkud svg:hover {
  color: white;
}

@media (max-width: 1024px) {
  .SelectDevice_deviceLeft__3mkud {
    padding-top: 0px;
    width: 80%;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    font-size: 5vw;
  }
}
/*# sourceMappingURL=SelectDevice.module.css.map */
.TabletImage_parralax__xFNuh {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  display: -webkit-flex;
  display: flex;
  padding: 15px;
}

@media (max-width: 767px) {
  .TabletImage_parralax__xFNuh {
    padding: 15px;
  }
}

.TabletImage_cardImageWrapper__2PLbC {
  -webkit-transform: translateZ(60px);
          transform: translateZ(60px);
  width: 100%;
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
  transition: 1s;
}

.TabletImage_cardImageWrapper__2PLbC img {
  width: 100%;
}

@media (max-width: 1024px) {
  .TabletImage_cardImageWrapper__2PLbC img {
    max-height: 200px;
    object-fit: contain;
  }
}

.TabletImage_leftOrder__uubr5 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: -200px;
}

@media (max-width: 1024px) {
  .TabletImage_leftOrder__uubr5 {
    margin: 0 auto;
  }
}

.TabletImage_rightOrder__CC5YT {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-right: -200px;
}

@media (max-width: 1024px) {
  .TabletImage_rightOrder__CC5YT {
    margin: 0 auto;
  }
}
/*# sourceMappingURL=TabletImage.module.css.map */
